export const numberFormatter = new Intl.NumberFormat('en-US');

/**
 * @description ### Resovle async response with error handling in order to make multiple async-await actions more predictable
 *
 * - Example response [ data, undefined ]
 * - Example response [ undefined, Error ]
 *
 *
 * When used with Promise.all([req1, req2, req3])
 * - Example response [ [data1, data2, data3], undefined ]
 * - Example response [ undefined, Error ]
 *
 *
 * When used with Promise.race([req1, req2, req3])
 * - Example response [ data, undefined ]
 * - Example response [ undefined, Error ]
 *
 * @param {Promise} promise
 * @returns {Promise} [ data, undefined ]
 * @returns {Promise} [ undefined, Error ]
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handlePromise = promise => {
  return promise.then(data => [data, undefined, false]).catch(error => Promise.resolve([undefined, error, true]));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleRequest = request => handlePromise(request);
export function formatDate(timestamp) {
  const date = typeof timestamp === 'number' ? new Date(timestamp) : timestamp;
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  });
}
export const formatUSPhone = number => number ? "".concat(number.slice(0, 2), " (").concat(number.slice(2, 5), ") ").concat(number.slice(5, 8), "-").concat(number.slice(8)) : '';